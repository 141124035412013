import React, { useEffect, useState, useCallback, useRef } from 'react';
import { ApplicationEdit } from 'Models/Application/Data/ApplicationEditModel';
import ContactNoteReadonly, {
   DefaultContactNote,
} from 'Models/Contact/Data/ContactNote';
import {
   useApplicationStatusesList,
   useLicenceSupervisions,
} from 'Util/Helpers/Metadata';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from 'react-md/lib/TextFields';
import SelectField from 'react-md/lib/SelectFields';
import { Button } from 'react-md/lib/Buttons';
import Loading from 'Components/Common/Loading';
import {
   ContactActions,
   ContactTypes,
   selectContact,
   selectContactNotes,
   selectActiveRegistrations,
} from 'State/Redux/ContactRedux';
import {
   selectIsLoading,
   selectIsError,
   selectIsSuccess,
   AsyncActions,
} from 'State/Redux/AsyncRedux';
import './CriteriaViewPanel.scss';
import {
   containsTrainingProviderCondition,
   isApplicationEditable,
} from 'Util/Helpers/Application';
import { CriteriaView } from './CriteriaView';
import { LicenceCriteriaView } from './LicenceCriteriaView';
import { useHistory } from 'react-router';
import { RegistrationApplicationTypes } from 'Util/Constants/ApplicationTypes';
import { Checkbox } from 'react-md/lib/SelectionControls';
import { isEqual } from 'lodash-es';
import {
   selectFeeRequestStatuses,
   selectCurrentLicenceYear,
   selectLicenceYears,
   selectApplicationStatuses,
   selectApplicationTypes,
   selectLicenceTypesMetadata,
} from 'State/Redux/MetadataRedux';
import { isEnterKeyPress } from 'Util/Helpers/Input';
import {
   ApplicationActions,
   ApplicationTypes,
   selectUpdateApplicationError,
   selectShouldSupervisionValidate,
} from 'State/Redux/ApplicationRedux';
import DateInput from 'Components/Common/DateInput/DateInput';
import Autocomplete from 'react-md/lib/Autocompletes';
import LoadingButton from 'Components/Common/LoadingButton';
import { toast } from 'react-toastify';
import DialogContainer from 'react-md/lib/Dialogs';
import { CancelApplicationModalBody } from '../CancelApplicationModalBody';
import { GenericModalBody } from '../GenericModalBody';
import { FEE_REQUEST_UNPAID } from 'Util/Constants/FeeRequestStatus';
import { ApplicationStatus } from 'Models/Metadata/ApplicationStatus';
import {
   ENTERED_IN_ERROR_STATUS,
   BOARD_PENDING_MEETING_STATUS,
} from 'Util/Constants/ApplicationStatus';
import {
   TRADESMAN_DRAINLAYER,
   TRADESMAN_GASFITTER,
   TRADESMAN_PLUMBER,
   JOURNEYMAN_DRAINLAYER,
   JOURNEYMAN_GASFITTER,
   JOURNEYMAN_PLUMBER,
} from 'Util/Constants/ApplicationTypes';
import {
   LICENCE_SUPERVISOR_CONDITION,
   LICENCE_SUPERVISOR_APPROVAL_CONDITION,
   SUPERVISION_CONDITIONS_WITH_ID,
   LICENCE_CHECK_SUPERVISOR_CITY_CONDITION,
   LICENCE_SUPERVISION_LICENCE_ACTIVE,
   LICENCE_UP_SKILLING,
} from 'Util/Constants/Conditions';
import {
   getInputValidationClassName,
   isValidDateBeforeNow,
} from 'Util/Helpers/Validation';
import { UpdateApplicationStatusModalBody } from '../UpdateApplicationStatusModalBody';
import { ApplicationType } from 'Models/Metadata/ApplicationType';
import {
   BoardMeetingDateActions,
   BoardMeetingDateTypes,
   selectBoardMeetingDates,
} from 'State/Redux/BoardMeetingDateRedux';
import { DISPLAY_DATE_FORMAT, NZ_TIMEZONE } from 'Util/Constants/Common';
import Moment from 'moment-timezone';
import SupervisionInput from 'Components/Common/SupervisionInput/SupervisionInput';
import SupervisionReadonly, {
   DefaultSupervision,
} from 'Models/Supervision/Data/Supervision';
import { DRAINLAYING, GASFITTING, PLUMBING } from 'Util/Constants/Disciplines';
import { DefaultLicenceDto } from 'Models/Licence/Dto/LicenceDto';
import produce from 'immer';
import {
   SupervisionActions,
   selectSupervisionSearchResult,
} from 'State/Redux/SupervisionRedux';
import { STYLECODE_SUPERVISION } from 'Util/Helpers/Conditions';
import { getSupervisionStatus } from 'Util/Helpers/Supervision';
import StateReadonly from 'State/Redux/StateModel';
import { ApplicationSupervisor } from 'Models/Application/Data/ApplicationSupervisor';
import PaymentDetailReadOnly from 'Models/Payment/Data/PaymentDetail';
import moment from 'moment-timezone';
import QuestionSaveBulkDto from 'Models/FitAndProper/Dto/QuestionSaveBulkDto';
import {
   FitAndProperTypes,
   FitAndProperActions,
   selectRegistrationFitAndProper,
} from 'State/Redux/FitAndProperRedux';
import { ContactNoteInputModelReadonly } from 'Models/Contact/Data/ContactNoteInputModel';

import Accordion from '../RegistrationApplication/Accordion';
import { FN_EMPTY_VOID } from 'Util/Helpers/Empty';
import { ReferenceApplicantForm } from '../RegistrationApplication/ContactDetailsSection';
import { FitAndProperApplicantForm } from '../RegistrationApplication/Section_2';
import { Criteria } from 'Models/Application/Dto/CriteriaDto';

const QUESTION_TYPE_REGISTRATION = 1;

const canCancelUnpaid = (
   appStatus?: ApplicationStatus | null,
   feeRequestStatusCode?: string | null
): boolean => {
   if (!appStatus) return false;

   const isUnpaid = feeRequestStatusCode === FEE_REQUEST_UNPAID;
   if (!isUnpaid) return false;

   return (
      appStatus.isFinalState &&
      appStatus.applicationStatusCode !== ENTERED_IN_ERROR_STATUS
   );
};

const applicationIsValid = (application: ApplicationEdit): boolean => {
   return (
      !!application.licenceYear &&
      !!application.applicationStatusCode &&
      !!application.enteredDate &&
      isValidDateBeforeNow(application.enteredDate) &&
      (application.applicationStatusCode !== BOARD_PENDING_MEETING_STATUS ||
         application.boardMeetingDate !== null)
   );
};

const getDisciplineCodeFromApplicationType = (
   applicationTypeCode: string
): string => {
   switch (applicationTypeCode) {
      case TRADESMAN_DRAINLAYER:
      case JOURNEYMAN_DRAINLAYER:
         return DRAINLAYING;
      case TRADESMAN_GASFITTER:
      case JOURNEYMAN_GASFITTER:
         return GASFITTING;
      case TRADESMAN_PLUMBER:
      case JOURNEYMAN_PLUMBER:
         return PLUMBING;
      default:
         return '';
   }
};

interface CriteriaViewPanelProps {
   isEditing: boolean;
   disableEditing: () => void;
   application: ApplicationEdit;
   payment: PaymentDetailReadOnly;
}

export const CriteriaViewPanel = ({
   isEditing,
   disableEditing,
   application,
   payment,
}: Readonly<CriteriaViewPanelProps>): JSX.Element => {
   const [applicationEdit, setApplication] = useState(application);
   const [noteObj, setNoteObj] = useState<ContactNoteReadonly>(
      DefaultContactNote
   );
   const [initNoteObj, setInitNoteObj] = useState<ContactNoteReadonly>(
      DefaultContactNote
   );
   const isResetting =
      !isEditing &&
      (!isEqual(application, applicationEdit) ||
         !isEqual(initNoteObj, noteObj));

   const applicantDetailsFormRef = useRef<ReferenceApplicantForm>({
      valid: () => '',
      submit: FN_EMPTY_VOID,
   });
   const applicantFitAndProperFormRef = useRef<FitAndProperApplicantForm>({
      valid: () => '',
      submit: FN_EMPTY_VOID,
   });
   const dispatch = useDispatch();
   const history = useHistory();
   const formFieldValidity: Record<string, boolean> = {
      discountAmountField: true,
      discountNoteField: true,
      noteField: true,
   };
   const [showUpdateModal, setShowUpdateModal] = useState(false);
   const [showCancelModal, setShowCancelModal] = useState(false);
   const [showWaiverModal, setShowWaiverModal] = useState(false);
   const [isWaiverApplied, setIsWaiverApplied] = useState(false);
   const [areFormFieldsValid, setAreFormFieldsValid] = useState(
      formFieldValidity
   );
   const [
      licenceSupervision,
      setLicenceSupervision,
   ] = useState<SupervisionReadonly | null>(null);

   const [fitAndProperValues, setFitAndProperValues] = useState<
      QuestionSaveBulkDto[]
   >();

   const validateFields = (): void => {
      const { discountAmount, discountNote } = applicationEdit;
      const { note } = noteObj;
      const regex = new RegExp('^[0-9]*.[0-9]{2}$');

      if (
         discountAmount !== undefined &&
         discountAmount !== null &&
         discountAmount === 0
      ) {
         setAreFormFieldsValid({
            ...areFormFieldsValid,
            discountAmountField: true,
         });
      }

      if (
         discountAmount !== undefined &&
         discountAmount !== null &&
         discountAmount !== 0
      ) {
         const discountAmountInDP =
            typeof discountAmount === 'number'
               ? discountAmount.toFixed(2)
               : discountAmount;

         setAreFormFieldsValid({
            ...areFormFieldsValid,
            discountAmountField: regex.test(discountAmountInDP),
         });
      }

      if (
         discountNote !== undefined &&
         discountNote !== null &&
         discountNote !== ''
      ) {
         setAreFormFieldsValid({
            ...areFormFieldsValid,
            discountNoteField: discountNote.toString().length <= 50,
         });
      }

      if (note !== undefined && note !== null && note !== '') {
         setAreFormFieldsValid({
            ...areFormFieldsValid,
            noteField: note.toString().length <= 500,
         });
      }
   };

   const callbackValidateFields = useCallback(
      validateFields,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
   );

   useEffect(() => {
      const { discountAmount, discountNote } = applicationEdit;
      if (discountAmount || discountNote) {
         setIsWaiverApplied(true);
      } else {
         setIsWaiverApplied(false);
      }
      callbackValidateFields();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isEditing, applicationEdit.applicationStatusCode]);

   const appTypes = useSelector(selectApplicationTypes);
   const appType =
      appTypes.find(
         at => at.applicationTypeCode === applicationEdit.applicationTypeCode
      ) || (({} as unknown) as ApplicationType);

   const resetAsyncRequests = useCallback((): void => {
      dispatch(
         AsyncActions.resetAsync([
            ApplicationTypes.UPDATE_APPLICATION_REQUEST,
            ApplicationTypes.CANCEL_APPLICATION_REQUEST,
         ])
      );
   }, [dispatch]);

   useEffect((): (() => void) => {
      return resetAsyncRequests;
   }, [resetAsyncRequests]);

   useEffect(() => {
      //only soft evaluating against board pending meeting for now
      if (applicationEdit.applicationId) {
         dispatch(
            ApplicationActions.getRegistrationFilesByApplicationRequest(
               applicationEdit.applicationId.toString()
            )
         );
      }
      if (
         !appType.isBoardApprovalRequired &&
         applicationEdit.applicationStatusCode === BOARD_PENDING_MEETING_STATUS
      ) {
         //generic message for reuse
         toast.error(
            'The selected status is invalid with this application type'
         );
      }
   }, [appType, applicationEdit, dispatch]);

   const contact = useSelector(selectContact);
   const fitAndProper = useSelector(selectRegistrationFitAndProper);

   const requiresTrainingProviders = containsTrainingProviderCondition(
      applicationEdit.conditions
   );
   const canCancel = isApplicationEditable(
      useSelector(selectApplicationStatuses),
      applicationEdit.applicationStatusCode
   );

   const loadTrainingProviders = (): void => {
      if (requiresTrainingProviders)
         dispatch(ContactActions.getTrainingProvidersRequest());
   };
   const loadBoardMeetingDates = (): void => {
      if (appType.isBoardApprovalRequired)
         dispatch(BoardMeetingDateActions.getBoardMeetingDatesRequest());
   };
   const loadFitAndProper = (): void => {
      dispatch(
         FitAndProperActions.getQuestionsAndAnswersByIdAndQuestionIdRequest(
            contact.userId,
            QUESTION_TYPE_REGISTRATION
         )
      );
   };
   const loadDependencies = (): void => {
      loadTrainingProviders();
      loadBoardMeetingDates();
      loadFitAndProper();
   };
   useEffect(loadDependencies, [
      requiresTrainingProviders,
      appType.isBoardApprovalRequired,
      contact.userId,
   ]);

   const dependenciesIsLoading = useSelector(
      selectIsLoading([
         ContactTypes.GET_TRAINING_PROVIDERS_REQUEST,
         BoardMeetingDateTypes.GET_BOARD_MEETING_DATES_REQUEST,
         FitAndProperTypes.GET_QUESTIONS_AND_ANSWERS_BY_ID_AND_QUESTION_ID_REQUEST,
         ApplicationTypes.PUT_REQUEST_ANOTHER_FILES_REQUEST,
      ])
   );
   const dependenciesIsError = useSelector(
      selectIsError([
         ContactTypes.GET_TRAINING_PROVIDERS_REQUEST,
         BoardMeetingDateTypes.GET_BOARD_MEETING_DATES_REQUEST,
         FitAndProperTypes.GET_QUESTIONS_AND_ANSWERS_BY_ID_AND_QUESTION_ID_REQUEST,
      ])
   );
   const updateIsLoading = useSelector(
      selectIsLoading([ApplicationTypes.UPDATE_APPLICATION_REQUEST])
   );
   const cancelIsLoading = useSelector(
      selectIsLoading([ApplicationTypes.CANCEL_APPLICATION_REQUEST])
   );
   const updateIsSuccess = useSelector(
      selectIsSuccess([ApplicationTypes.UPDATE_APPLICATION_REQUEST])
   );
   const cancelIsSuccess = useSelector(
      selectIsSuccess([ApplicationTypes.CANCEL_APPLICATION_REQUEST])
   );
   const shouldSupervisionValidate = useSelector(
      selectShouldSupervisionValidate
   );
   const updateContactIsSuccess = useSelector(
      selectIsSuccess([ContactTypes.SAVE_CONTACT_DATA_REQUEST])
   );
   const updateContactIsLoading = useSelector(
      selectIsLoading([ContactTypes.SAVE_CONTACT_DATA_REQUEST])
   );

   const isLoading = updateIsLoading || updateContactIsLoading;

   if ((updateIsSuccess || cancelIsSuccess) && updateContactIsSuccess) {
      if (updateIsSuccess)
         toast.success(
            `Application #${applicationEdit.applicationId} has been updated.`
         );
      if (cancelIsSuccess)
         toast.success(
            `Application #${applicationEdit.applicationId} has been cancelled.`
         );

      resetAsyncRequests();
      disableEditing();
   }

   const updateHasErrors = useSelector(
      selectIsError([
         ApplicationTypes.UPDATE_APPLICATION_REQUEST,
         ApplicationTypes.CANCEL_APPLICATION_REQUEST,
      ])
   );
   const updateErrors = useSelector(selectUpdateApplicationError);
   if (updateHasErrors && updateErrors && updateErrors.errorMessage) {
      toast.error(updateErrors.errorMessage);
      resetAsyncRequests();
   }

   const loadNotes = (): void => {
      dispatch(
         ContactActions.getContactNotesRequest(contact.contactId.toString())
      );
   };
   useEffect(loadNotes, [contact.contactId, dispatch]);

   const applicationNoteObject = useSelector(selectContactNotes);

   const assignApplicationNote = useCallback(() => {
      if (applicationNoteObject && applicationNoteObject.length !== 0) {
         applicationNoteObject.filter(note => {
            if (note.applicationId === application.applicationId) {
               setNoteObj(note);
               setInitNoteObj(note);
            }
            return false;
         });
      }
   }, [application.applicationId, applicationNoteObject]);

   useEffect(assignApplicationNote, [applicationNoteObject]);

   const isALicenceCondition = (conditionCode: string): boolean =>
      [
         LICENCE_UP_SKILLING,
         LICENCE_SUPERVISOR_APPROVAL_CONDITION,
         LICENCE_SUPERVISION_LICENCE_ACTIVE,
         LICENCE_CHECK_SUPERVISOR_CITY_CONDITION,
      ].includes(conditionCode);

   const filterLicenceConditions = (
      listCriteria: readonly Criteria[]
   ): Criteria[] =>
      listCriteria.map(
         (ac): Criteria => ({
            criteriaNumber: ac.criteriaNumber,
            conditions: ac.conditions.filter(
               (cond: string) => !isALicenceCondition(cond)
            ),
         })
      );

   const filteredLicenceCriteria = application.isRegistrationApplication // means is OnlineRegistration
      ? filterLicenceConditions(applicationEdit.criteria)
      : applicationEdit.criteria;

   const activeRegistrations = useSelector(selectActiveRegistrations);

   const isPreviousRegistration = (date: string): boolean => {
      const registrationDate = moment(date, 'YYYY-MM-DD');
      return registrationDate.isBefore(`${applicationEdit.licenceYear}-04-01`); // Before the Renewal Licence date
   };

   const getLicenceConditions = (listCriteria: readonly Criteria[]): string[] =>
      applicationEdit.criteria.length > 0
         ? applicationEdit.criteria[0].conditions.filter(x => {
              if (x === LICENCE_UP_SKILLING) {
                 return (
                    activeRegistrations.filter(reg =>
                       isPreviousRegistration(reg.registeredDate)
                    ).length > 0
                 );
              }
              return isALicenceCondition(x);
           })
         : [];

   const criteria = filteredLicenceCriteria.map(
      (ac): JSX.Element => {
         return (
            <CriteriaView
               key={ac.criteriaNumber}
               isEditing={isEditing}
               application={applicationEdit}
               updateApplication={setApplication}
               criteria={ac}
               fitAndProper={fitAndProperValues}
               setFitAndProper={setFitAndProperValues}
            />
         );
      }
   );

   const currentLicenceYear = useSelector(selectCurrentLicenceYear);
   const licenceYears = useSelector(selectLicenceYears);
   const licenceYearDescriptions = licenceYears.map(ly => ly.description);

   const appLicenceYear = licenceYears.find(
      ly => ly.licenceYearId === applicationEdit.licenceYear
   );
   const [licenceYearDescription, setLicenceYearDescription] = useState(
      appLicenceYear
         ? appLicenceYear.description
         : currentLicenceYear.description
   );
   const isRegistrationApplication = applicationEdit.isRegistrationApplication;

   const statusForRegistrationApplication = [
      'DSUB', //Documents submitted
      'DECL', //Declined
      'PBAP', //Pending Board Approval
      'BIP', // Batch Insufficient Prerequisites
      'PPAY', //On Hold Pending Payment
      'PREG', //On Hold Pending Registrar
      'PDOC', //On Hold Pending Document
      'REVW', //Under Review
      'N', //New
      'BA', //Board Approved
   ];

   const applicationStatusList = useApplicationStatusesList(
      appType.applicationTypeCode
   ).filter(item => {
      if (isRegistrationApplication) {
         return statusForRegistrationApplication.includes(item.value);
      } else {
         return (
            item.value === 'BA' ||
            item.value === 'N' ||
            !statusForRegistrationApplication.includes(item.value)
         );
      }
   });

   const appStatuses = useSelector(selectApplicationStatuses);
   const appStatus = appStatuses.find(
      status =>
         applicationEdit.applicationStatusCode === status.applicationStatusCode
   );

   const boardMeetingDates = useSelector(selectBoardMeetingDates);
   const boardMeetingDate = boardMeetingDates.find(
      (bmd): boolean =>
         bmd.boardMeetingDateId === applicationEdit.boardMeetingDate
   );
   const selectedBoardMeetingDateDescription = boardMeetingDate
      ? Moment(boardMeetingDate.boardMeetingDate).format(DISPLAY_DATE_FORMAT)
      : '';
   const [
      boardMeetingDateDescription,
      setBoardMeetingDateDescription,
   ] = useState(selectedBoardMeetingDateDescription);
   const [requiresSupervision, setRequiresSupervision] = useState(false);

   useEffect(() => {
      application.conditions.forEach(condition => {
         if (condition.styleCode === STYLECODE_SUPERVISION) {
            if (
               condition.conditionCode === 'LCSP' &&
               !application.createLicence
            )
               return;

            setRequiresSupervision(true);
            dispatch(
               ApplicationActions.getApplicationSupervisorRequest({
                  applicationType: application.applicationTypeCode,
                  contactId: contact.contactId,
                  licenceYear: application.licenceYear,
                  registrationNumber: Number(condition.textValue),
               })
            );
         }
      });
      return (): void => {
         dispatch(ApplicationActions.setShouldSupervisionValidate(false));
      };
   }, [
      application.conditions,
      application.createLicence,
      application.applicationTypeCode,
      application.licenceYear,
      contact.contactId,
      dispatch,
   ]);

   // TODO -- Remove this duplicated logic from ConditionSupervision
   const supervisorSearchResult = useSelector(
      (state: StateReadonly): ApplicationSupervisor | null => {
         return state.application.editModel.supervisor || null;
      }
   );
   const licenceSupervisorSearchResult = useSelector(
      selectSupervisionSearchResult
   );
   const currentContact = useSelector(selectContact);
   const licenceSupervisionMetadata = useLicenceSupervisions();

   const licenceTypesMetadata = useSelector(selectLicenceTypesMetadata);

   // This shouldn't be null if application type exists
   const appTypeModel = appTypes.find(a => {
      return a.applicationTypeCode === application.applicationTypeCode;
   });
   const licenceTypeCode = appTypeModel ? appTypeModel.licenceTypeCode : '';
   const licenceTypeCodeNotNull = licenceTypeCode ? licenceTypeCode : '';

   const licenceTypeMetadata = licenceTypesMetadata.find(l => {
      return l.licenceTypeCode === licenceTypeCodeNotNull;
   });

   // If historicSupervisionStatus is not null, then the application is historic and we rely on status from API
   const supervisionStatus =
      supervisorSearchResult && supervisorSearchResult.historicSupervisionStatus
         ? supervisorSearchResult.historicSupervisionStatus
         : getSupervisionStatus(
              licenceSupervisionMetadata,
              supervisorSearchResult
                 ? supervisorSearchResult.supervisorContact
                 : licenceSupervisorSearchResult
                 ? licenceSupervisorSearchResult
                 : null,
              licenceTypeCodeNotNull,
              licenceTypeMetadata ? licenceTypeMetadata.disciplineCode : '',
              application.licenceYear,
              currentContact
           );

   // TODO -- END

   if (
      boardMeetingDate &&
      boardMeetingDateDescription !== selectedBoardMeetingDateDescription
   )
      setBoardMeetingDateDescription(selectedBoardMeetingDateDescription);

   const boardMeetingDateOptions = boardMeetingDates.map((bmd): string =>
      Moment(bmd.boardMeetingDate).format(DISPLAY_DATE_FORMAT)
   );
   const boardMeetingDateOption = appType.isBoardApprovalRequired ? (
      <div className="md-cell md-cell--12">
         <Autocomplete
            showUnfilteredData
            id="app-board-meeting-date"
            label="Board Meeting Date"
            placeholder={DISPLAY_DATE_FORMAT}
            className="md-cell md-cell--12"
            data={boardMeetingDateOptions}
            filter={Autocomplete.caseInsensitiveFilter}
            value={boardMeetingDate ? boardMeetingDateDescription : ''}
            disabled={!isEditing}
            onAutocomplete={(suggestion): void => {
               const newMeetingDate = boardMeetingDates.find(
                  (bmd): boolean =>
                     Moment(bmd.boardMeetingDate).format(
                        DISPLAY_DATE_FORMAT
                     ) === suggestion.toString()
               );
               const newMeetingDateId = newMeetingDate
                  ? newMeetingDate.boardMeetingDateId
                  : null;

               setApplication({
                  ...applicationEdit,
                  boardMeetingDate: newMeetingDateId,
               });
               setBoardMeetingDateDescription(suggestion.toString());
            }}
            onChange={(val): void => {
               const newMeetingDate = boardMeetingDates.find(
                  (bmd): boolean =>
                     Moment(bmd.boardMeetingDate).format(
                        DISPLAY_DATE_FORMAT
                     ) === val.toString()
               );
               const newMeetingDateId = newMeetingDate
                  ? newMeetingDate.boardMeetingDateId
                  : null;

               setApplication({
                  ...applicationEdit,
                  boardMeetingDate: newMeetingDateId,
               });
               setBoardMeetingDateDescription(val.toString());
            }}
         />
      </div>
   ) : (
      <></>
   );

   const feeRequestStatuses = useSelector(selectFeeRequestStatuses);
   const matchingFeeRequest = feeRequestStatuses.find(
      (frs): boolean =>
         frs.feeRequestStatusCode === applicationEdit.feeRequestStatusCode
   );

   const feeRequestDescription = applicationEdit.feeOverride
      ? 'Paid (Override)'
      : !applicationEdit.hasFees
      ? 'No Fees'
      : matchingFeeRequest
      ? matchingFeeRequest.description
      : 'Unknown';

   const hasCreateLicenceOption = RegistrationApplicationTypes.includes(
      applicationEdit.applicationTypeCode
   );

   const isLicenceWaiver =
      applicationEdit.isLicenceWaiver === null
         ? undefined
         : applicationEdit.isLicenceWaiver;

   const appIsValid = applicationIsValid(applicationEdit);

   useEffect(() => {
      //check if application has a supervisor condition for licence creations
      const supervisorRequiredCondition = applicationEdit.conditions.find(
         c => c.conditionCode === LICENCE_SUPERVISOR_CONDITION
      );
      const requiresLicenceSupervisor =
         applicationEdit.createLicence && supervisorRequiredCondition;

      if (requiresLicenceSupervisor) {
         //create the supervision for the licence.
         //only registrations require this. We need to map the registration type to a discipline
         setLicenceSupervision({
            ...DefaultSupervision,
            registrationNumber: Number(supervisorRequiredCondition?.textValue),
            licence: {
               ...DefaultLicenceDto,
               disciplineCode: getDisciplineCodeFromApplicationType(
                  applicationEdit.applicationTypeCode
               ),
               licenceTypeCode: licenceTypeCodeNotNull,
               licenceYearNumber: applicationEdit.licenceYear,
            },
         });
      }
   }, [
      applicationEdit.conditions,
      applicationEdit.createLicence,
      applicationEdit.applicationTypeCode,
      applicationEdit.licenceYear,
      licenceTypeCodeNotNull,
   ]);

   const updateLicenceSupervisorCondition = (textValue: string): void => {
      setApplication(
         produce(applicationEdit, (draftApplicationEdit): void => {
            const draftSupervisorRequiredCondition = draftApplicationEdit.conditions.find(
               c => c.conditionCode === LICENCE_SUPERVISOR_CONDITION
            );
            if (draftSupervisorRequiredCondition) {
               draftSupervisorRequiredCondition.textValue = textValue;
            }
         })
      );
   };

   const updateLicenceSupervisorConditionRegistration = (
      textValue: string
   ): void => {
      setApplication(
         produce(applicationEdit, (draftApplicationEdit): void => {
            const draftSupervisorRequiredCondition = draftApplicationEdit.conditions.find(
               c => c.conditionCode === LICENCE_SUPERVISION_LICENCE_ACTIVE
            );
            if (draftSupervisorRequiredCondition) {
               draftSupervisorRequiredCondition.textValue = textValue;
            }
         })
      );
   };

   const getSupervisorValue = (): string => {
      if (applicationEdit?.isRegistrationApplication) {
         return (
            applicationEdit?.conditions?.find(
               c => c.conditionCode === LICENCE_SUPERVISION_LICENCE_ACTIVE
            )?.textValue ?? ''
         );
      }
      return (
         applicationEdit?.conditions?.find(
            c => c.conditionCode === LICENCE_SUPERVISOR_CONDITION
         )?.textValue ?? ''
      );
   };

   const updateLicenceSupervisorApprovalCondition = (
      isChecked: boolean
   ): void => {
      setApplication(
         produce(applicationEdit, (draftApplicationEdit): void => {
            const draftSupervisorApprovalCondition = draftApplicationEdit.conditions.find(
               c => c.conditionCode === LICENCE_SUPERVISOR_APPROVAL_CONDITION
            );
            if (draftSupervisorApprovalCondition) {
               draftSupervisorApprovalCondition.isChecked = isChecked;
            }
         })
      );
   };

   const changeFeeRequest = (val: boolean): void => {
      dispatch(
         ApplicationActions.updateFeeRequest(
            +applicationEdit.applicationId,
            val ? 2 : 0
         )
      );

      setApplication({
         ...applicationEdit,
         createLicence: val,
      });
   };

   const createLicenceOption = hasCreateLicenceOption ? (
      <div className="md-cell md-cell--12">
         <Checkbox
            id="app-create-licence"
            className="md-cell md-cell--12"
            label="Create Licence"
            checked={!!applicationEdit.createLicence}
            name="app-create-licence"
            disabled={
               !(
                  isEditing &&
                  applicationEdit.isRegistrationApplication &&
                  applicationEdit.applicationStatusCode === 'PPAY'
               )
            }
            onChange={changeFeeRequest}
         />
         {licenceSupervision && (
            <>
               <SupervisionInput
                  selectedSupervision={licenceSupervision}
                  onSave={(supervisionRegistrationNumber: string): void => {
                     updateLicenceSupervisorCondition(
                        supervisionRegistrationNumber
                     );
                  }}
                  onChange={(val): void => {
                     if (applicationEdit.isRegistrationApplication) {
                        updateLicenceSupervisorConditionRegistration(val);
                     } else {
                        updateLicenceSupervisorCondition(val);
                     }
                     dispatch(
                        ApplicationActions.setShouldSupervisionValidate(true)
                     );
                  }}
                  disabled={!isEditing}
                  value={getSupervisorValue()}
               />
               {!applicationEdit.isRegistrationApplication && (
                  <>
                     <Checkbox
                        id="app-licence-supervisor-approval"
                        className="md-cell md-cell--12"
                        label="Licence Supervisor Approval"
                        checked={
                           applicationEdit?.conditions?.find(
                              c =>
                                 c.conditionCode ===
                                 LICENCE_SUPERVISOR_APPROVAL_CONDITION
                           )?.isChecked
                        }
                        name="app-licence-supervisor-approval"
                        onChange={(val): void => {
                           updateLicenceSupervisorApprovalCondition(val);
                        }}
                     />

                     <Checkbox
                        id="app-licence-supervisor-same-city"
                        className="md-cell md-cell--12"
                        label="Supervision location approved"
                        checked={
                           applicationEdit?.conditions?.find(
                              c =>
                                 c.conditionCode ===
                                 LICENCE_CHECK_SUPERVISOR_CITY_CONDITION
                           )?.isChecked
                        }
                        name="app-licence-supervisor-approval"
                        onChange={(val): void => {
                           updateLicenceSupervisorApprovalCondition(val);
                        }}
                     />
                  </>
               )}
            </>
         )}
      </div>
   ) : (
      <></>
   );

   const renderWaiverComponents = (): JSX.Element => {
      return (
         <>
            <div className="md-cell md-cell--12">
               <TextField
                  id="waiver-amount"
                  className="md-cell md-cell--12"
                  label="Waiver $ Amount"
                  placeholder="Enter Waiver Amount"
                  lineDirection="center"
                  disabled={!isEditing}
                  required
                  error={!areFormFieldsValid.discountAmountField}
                  errorText={
                     isEditing
                        ? 'Please input waiver amount to 2 decimal place.'
                        : ''
                  }
                  value={
                     applicationEdit.discountAmount !== undefined &&
                     typeof applicationEdit.discountAmount === 'number'
                        ? applicationEdit.discountAmount.toFixed(2)
                        : applicationEdit.discountAmount
                  }
                  onChange={(val): void => {
                     const regex = new RegExp('^[0-9]*.[0-9]{2}$');
                     setAreFormFieldsValid({
                        ...areFormFieldsValid,
                        discountAmountField: regex.test(val.toString()),
                     });
                     setApplication({
                        ...applicationEdit,
                        discountAmount: val,
                     });
                  }}
               />
            </div>
            <div className="md-cell md-cell--12">
               <Checkbox
                  id="app-licence-waiver"
                  className="md-cell md-cell--12"
                  label="Apply waiver to Licence application only"
                  disabled={!isEditing}
                  checked={isLicenceWaiver}
                  name="app-licence-waiver"
                  onChange={(val): void => {
                     setApplication({
                        ...applicationEdit,
                        isLicenceWaiver: val,
                     });
                  }}
               />
            </div>
            <div className="md-cell md-cell--12">
               <TextField
                  id="waiver-note"
                  placeholder="Add a note for the waiver amount to reflect on the practitioner's payment page"
                  label="Waiver Note"
                  className="md-cell md-cell--12"
                  value={applicationEdit.discountNote}
                  disabled={!isEditing}
                  error={!areFormFieldsValid.discountNoteField}
                  errorText={
                     isEditing
                        ? 'Please remove some text, there is a limit of ' +
                          50 +
                          ' characters'
                        : ''
                  }
                  onChange={(val): void => {
                     setAreFormFieldsValid({
                        ...areFormFieldsValid,
                        discountNoteField: val.toString().length <= 50,
                     });
                     setApplication({ ...applicationEdit, discountNote: val });
                  }}
               />
            </div>
         </>
      );
   };

   const renderRegistrationApplicationComponents = (
      userId: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fitAndProper: any
   ): JSX.Element => {
      return (
         <div>
            <TextField
               id="conditions-to-licence"
               placeholder="Add note against on hold - explain why."
               label="Note"
               rows={6}
               className="md-cell md-cell--12"
               value={noteObj.note}
               disabled={!isEditing}
               error={!areFormFieldsValid.noteField}
               errorText={
                  isEditing
                     ? 'Please remove some text, there is a limit of ' +
                       500 +
                       ' characters'
                     : ''
               }
               onChange={(val): void => {
                  setAreFormFieldsValid({
                     ...areFormFieldsValid,
                     noteField: val.toString().length <= 500,
                  });
                  setNoteObj({ ...noteObj, note: val });
               }}
            />
            <Accordion
               isEditing={isEditing}
               contactId={contact.contactId}
               applicantDetailsRef={applicantDetailsFormRef}
               applicantFitAndProperRef={applicantFitAndProperFormRef}
               userId={userId}
               fitAndProper={fitAndProper}
               applicationId={applicationEdit.applicationId}
            />
         </div>
      );
   };

   const applicationIsCancellable =
      (isEditing && canCancel) ||
      canCancelUnpaid(
         appStatus,
         matchingFeeRequest ? matchingFeeRequest.feeRequestStatusCode : ''
      );

   const saveApplication = (): void => {
      validateFields();
      const isValidApplicantDetailsForm = applicantDetailsFormRef?.current?.valid();
      const isValidApplicantFitAndProperForm = applicantFitAndProperFormRef?.current?.valid();
      const arrayOfInvalidFields = Object.keys(areFormFieldsValid).filter(
         (filter): boolean => {
            return areFormFieldsValid[filter] === false;
         }
      );
      const isFormValid = arrayOfInvalidFields.length === 0 ? true : false;

      if (isValidApplicantFitAndProperForm) {
         toast.error('Please select at least one country');
      }

      if (
         !isValidApplicantDetailsForm &&
         !isValidApplicantFitAndProperForm &&
         isFormValid
      ) {
         const newNote: ContactNoteInputModelReadonly = {
            contactNoteId: noteObj.contactNoteId,
            contactId: contact.contactId,
            note: noteObj.note,
            attachment: null,
            attachmentFileName: '',
            attachmentFileType: '',
            applicationId: applicationEdit.applicationId,
         };

         dispatch(ApplicationActions.updateApplicationRequest(applicationEdit));
         dispatch(ApplicationActions.setShouldSupervisionValidate(false));
         dispatch(ContactActions.saveContactNoteRequest(newNote));
         applicantDetailsFormRef?.current?.submit();
         applicantFitAndProperFormRef?.current?.submit();
         //save fit and proper
         if (fitAndProperValues && fitAndProperValues.length) {
            dispatch(
               FitAndProperActions.saveAnswerListByIdRequest(
                  contact.userId,
                  fitAndProperValues
               )
            );
         }
      } else {
         if (isValidApplicantDetailsForm) {
            toast.error(
               `Applicant's and public registration details ${isValidApplicantDetailsForm}`
            );
         } else if (!isFormValid) {
            arrayOfInvalidFields.map(field => {
               if (field === 'noteField') {
                  toast.error(`Note field is not valid.`);
               }
               if (field === 'discountNoteField') {
                  toast.error(`Waiver Note field is not valid.`);
               }
               if (field === 'discountAmountField') {
                  toast.error(`Waiver Amount field is not valid.`);
               }
               return null;
            });
         }
      }
   };

   const cancelApplication = (): void => {
      dispatch(
         ApplicationActions.cancelApplicationRequest(
            applicationEdit.applicationId.toString()
         )
      );
   };

   // clears state when resetting
   if (isResetting) {
      setApplication(application);
      setNoteObj(initNoteObj);
      const oldLicenceYear = licenceYears.find(
         ly => ly.licenceYearId === application.licenceYear
      );
      setLicenceYearDescription(
         oldLicenceYear
            ? oldLicenceYear.description
            : currentLicenceYear.description
      );
      const licenceSupervisor = application?.conditions?.find(c => {
         return SUPERVISION_CONDITIONS_WITH_ID.some(
            sc => sc === c.conditionCode
         );
      })?.textValue;
      if (licenceSupervisor) {
         dispatch(
            SupervisionActions.searchSupervisorRequest(licenceSupervisor)
         );
      }

      setShowUpdateModal(false);
      setShowCancelModal(false);
      setShowWaiverModal(false);
   }

   // eslint-disable-next-line
   const supervisionIdCondition = applicationEdit?.conditions?.find(c => {
      return SUPERVISION_CONDITIONS_WITH_ID.some(sc => sc === c.conditionCode);
   });

   const licenceSupervisorEdit = supervisionIdCondition?.textValue;
   const supervisionStatusSaveDisabled =
      supervisionIdCondition?.conditionCode === LICENCE_SUPERVISOR_CONDITION
         ? supervisionStatus.supervisorStatus !== 'Valid'
         : supervisionStatus.supervisorStatus === 'Not Found';

   const cancelButtonDisabled =
      !appIsValid || !applicationIsCancellable || isLoading;

   const saveButtonDisabled =
      !appIsValid ||
      !isEditing ||
      cancelIsLoading ||
      (requiresSupervision &&
         !!licenceSupervisorEdit &&
         licenceSupervisorEdit.length > 0 &&
         supervisionStatusSaveDisabled &&
         shouldSupervisionValidate);

   // Route to Payment using FeeRequest id for trainee fee-requests
   const feeRequestLink =
      applicationEdit.feeRequest &&
      applicationEdit.feeRequest.feeRequestId &&
      applicationEdit.feeRequest.applicationId &&
      applicationEdit.feeRequest.applicationId !== applicationEdit.applicationId
         ? `/payment?feeRequestId=${applicationEdit.feeRequest.feeRequestId}&returnUrl=${history.location.pathname}`
         : `/payment?applicationId=${applicationEdit.applicationId}&returnUrl=${history.location.pathname}`;

   return (
      <Loading
         isLoading={dependenciesIsLoading || isLoading}
         isError={dependenciesIsError}
         tryReload={loadDependencies}
         overlayOnChildren={true}
      >
         <>
            <DialogContainer
               id="waiver-application-dialog"
               visible={showWaiverModal}
               className="edit-dialog"
               title="Add Waiver"
               onHide={(): void => {
                  setShowWaiverModal(false);
               }}
               width={600}
               portal
            >
               <GenericModalBody
                  textModal="Do you want to add a waiver to this application?"
                  onConfirm={(): void => {
                     setShowWaiverModal(false);
                     setIsWaiverApplied(true);
                  }}
                  onCancel={(): void => {
                     setShowWaiverModal(false);
                     setIsWaiverApplied(false);
                  }}
               />
            </DialogContainer>
            <DialogContainer
               id="cancel-application-dialog"
               visible={showCancelModal}
               className="edit-dialog"
               title="Cancel Application"
               onHide={(): void => {
                  setShowCancelModal(false);
               }}
               width={600}
               portal
            >
               <CancelApplicationModalBody
                  onConfirm={(): void => {
                     cancelApplication();
                     setShowCancelModal(false);
                  }}
                  onCancel={(): void => {
                     setShowCancelModal(false);
                  }}
               />
            </DialogContainer>
            <DialogContainer
               id="application-final-state-dialog"
               visible={showUpdateModal}
               className="final-state-dialog"
               title="Update Application"
               onHide={(): void => {
                  setShowUpdateModal(false);
               }}
               width={600}
               portal
            >
               <UpdateApplicationStatusModalBody
                  onConfirm={(): void => {
                     saveApplication();
                     setShowUpdateModal(false);
                  }}
                  onCancel={(): void => {
                     setShowUpdateModal(false);
                  }}
                  status={
                     appStatus && appStatus.description
                        ? appStatus.description
                        : 'Unknown'
                  }
               />
            </DialogContainer>
            <div className="md-grid md-cell md-cell--12">
               <div className="md-cell md-cell--6">
                  <>
                     {criteria}
                     {applicationEdit.createLicence && (
                        <LicenceCriteriaView
                           isEditing={isEditing}
                           application={applicationEdit}
                           updateApplication={setApplication}
                           conditions={getLicenceConditions(
                              applicationEdit.criteria
                           )}
                        />
                     )}
                  </>
               </div>

               <div className="md-cell md-cell--6">
                  <div className="md-cell md-cell--12">
                     <TextField
                        id="app-fee-request"
                        className="md-cell md-cell--12"
                        label="Fee Request Status"
                        lineDirection="center"
                        value={feeRequestDescription}
                        disabled
                     />
                  </div>
                  <div className="md-cell md-cell--12">
                     <Autocomplete
                        showUnfilteredData
                        id="licence-year"
                        label="Licence Year"
                        placeholder="Licence Year"
                        className="md-cell md-cell--12"
                        inputClassName={getInputValidationClassName(
                           !applicationEdit.licenceYear
                        )}
                        data={licenceYearDescriptions}
                        error={!applicationEdit.licenceYear}
                        filter={Autocomplete.caseInsensitiveFilter}
                        defaultValue={currentLicenceYear.description}
                        value={licenceYearDescription}
                        disabled={!isEditing}
                        onAutocomplete={(suggestion): void => {
                           const year = licenceYears.find(
                              (ly): boolean =>
                                 ly.description === suggestion.toString()
                           );
                           const yearId = year ? year.licenceYearId : 0;

                           setApplication({
                              ...applicationEdit,
                              licenceYear: yearId,
                           });
                           setLicenceYearDescription(suggestion.toString());
                        }}
                        onChange={(val): void => {
                           const year = licenceYears.find(
                              (ly): boolean => ly.description === val.toString()
                           );
                           const yearId = year ? year.licenceYearId : 0;

                           setApplication({
                              ...applicationEdit,
                              licenceYear: yearId,
                           });
                           setLicenceYearDescription(val.toString());
                        }}
                     />
                  </div>
                  <div className="md-cell md-cell--12">
                     <SelectField
                        id="app-status"
                        lineDirection="center"
                        className="md-cell md-cell--12"
                        label="Status"
                        inputClassName={getInputValidationClassName(
                           !applicationEdit.applicationStatusCode
                        )}
                        menuItems={applicationStatusList}
                        position={SelectField.Positions.BELOW}
                        value={applicationEdit.applicationStatusCode}
                        defaultValue=" "
                        required
                        error={!applicationEdit.applicationStatusCode}
                        errorText="Please select a status"
                        onChange={(val): void => {
                           if (val.toString() === 'PPAY') {
                              setShowWaiverModal(true);
                              setApplication({
                                 ...applicationEdit,
                                 applicationStatusCode: val.toString(),
                                 discountAmount: 0,
                                 discountNote: '',
                              });
                           } else {
                              setIsWaiverApplied(false);
                              setApplication({
                                 ...applicationEdit,
                                 applicationStatusCode: val.toString(),
                                 discountAmount: 0,
                                 discountNote: '',
                              });
                           }
                        }}
                        disabled={!isEditing}
                     />
                     {payment?.payment?.createdBy && (
                        <span className="payment-status-detail">
                           {`${payment.payment.createdBy}${
                              payment.payment.createdOn
                                 ? ` 
                           ${moment(payment.payment.createdOn)
                              .tz(NZ_TIMEZONE)
                              .format('DD/MM/YYYY H:mma')}`
                                 : ''
                           }`}
                        </span>
                     )}
                  </div>
                  {isWaiverApplied ? renderWaiverComponents() : null}
                  <div className="md-cell md-cell--12">
                     <DateInput
                        id="app-entered-date"
                        className="md-cell md-cell--12"
                        label="Application Received Date"
                        inputClassName={getInputValidationClassName(
                           !applicationEdit.enteredDate
                        )}
                        onChange={(val): void => {
                           setApplication({
                              ...applicationEdit,
                              enteredDate: val,
                           });
                        }}
                        value={applicationEdit.enteredDate}
                        error={
                           !applicationEdit.enteredDate ||
                           !isValidDateBeforeNow(applicationEdit.enteredDate)
                        }
                        errorText="Please enter a valid received date"
                        disabled={!isEditing}
                     />
                  </div>
                  {boardMeetingDateOption}
                  {createLicenceOption}
                  {isRegistrationApplication
                     ? renderRegistrationApplicationComponents(
                          contact.userId,
                          fitAndProper
                       )
                     : null}
               </div>
            </div>
            <div className="md-cell md-cell--6-desktop md-cell--6-desktop-offset md-cell--12-tablet md-cell--12-phone md-cell--0-phone-offset md-cell--0-tablet-offset criteria-view-panel-button-container">
               <Button
                  flat
                  swapTheming
                  secondary
                  className="md-cell md-cell--4 criteria-view-panel-buttons"
                  disabled={!applicationEdit.hasFees}
                  onClick={(): void => {
                     history.push(feeRequestLink);
                  }}
               >
                  Fee Request
               </Button>
               <LoadingButton
                  flat
                  swapTheming
                  primary
                  className="md-cell md-cell--4 criteria-view-panel-buttons"
                  disabled={saveButtonDisabled}
                  isLoading={isLoading}
                  onClick={(): void => {
                     const isFinalState = appStatus && appStatus.isFinalState;
                     if (isFinalState) setShowUpdateModal(true);
                     else saveApplication();
                  }}
                  onKeyUp={(evt): void => {
                     if (!isEnterKeyPress(evt)) return;

                     const isFinalState = appStatus && appStatus.isFinalState;
                     if (isFinalState) setShowUpdateModal(true);
                     else saveApplication();
                  }}
               >
                  Save
               </LoadingButton>
               {!isRegistrationApplication && (
                  <LoadingButton
                     flat
                     swapTheming
                     secondary
                     className="md-cell md-cell--4 red-btn criteria-view-panel-buttons"
                     disabled={cancelButtonDisabled}
                     isLoading={cancelIsLoading}
                     onClick={(): void => {
                        setShowCancelModal(true);
                     }}
                     onKeyUp={(evt): void => {
                        if (isEnterKeyPress(evt)) setShowCancelModal(true);
                     }}
                  >
                     Cancel Application
                  </LoadingButton>
               )}
            </div>
         </>
      </Loading>
   );
};
