import { createReducer } from 'reduxsauce';
import { AnyAction } from 'redux';
import PendingApplicationResponse from 'Models/Application/Data/PendingApplicationResponse';
import ApiError from 'Models/Other/ApiError';
import ApplicationDto from 'Models/Application/Dto/ApplicationDto';
import { ApplicationEdit } from 'Models/Application/Data/ApplicationEditModel';
import { ApplicationSupervisor } from 'Models/Application/Data/ApplicationSupervisor';
import StateReadonly from './StateModel';
import { ApplicationCreate } from 'Models/Application/Data/ApplicationCreate';
import { ApplicationCreateError } from 'Models/Application/Data/ApplicationCreateError';
import { ApplicationEditError } from 'Models/Application/Data/ApplicationEditError';
import { ApplicationSearchQuery } from 'Models/Application/Dto/ApplicationSearchQuery';
import ApplicationSearchResult from 'Models/Application/Data/ApplicationSearchResult';
import { createScopedActions } from '.';
import { ApplicationPendingReviewReadonly } from 'Models/Application/Data/ApplicationPendingReview';
import { ApplicationStatusEdit } from 'Models/Application/Data/ApplicationStatusEdit';
import { ApplicationReceivedDateReadonly } from 'Models/Application/Data/ApplicationReceivedDateUpdate';
import {
   PendingRegistrationApplications,
   PendingRegistrationApplicationsReadonly,
} from 'Models/Application/Data/PendingRegistrationApplications';
import { DISPLAY_DATE_TIME_FORMAT_12 } from 'Util/Constants/Common';
import moment from 'moment';
import { ApplicationRequestFiles } from 'Models/Application/Data/ApplicationRequestFilesModel';

/* ------------- Interfaces for ReduxSauce ------------- */
interface ApplicationState {
   pendingApplicationResponse: readonly PendingApplicationResponse[];
   applicationHistory: readonly ApplicationDto[];
   application: ApplicationDto;
   editModel: ApplicationEdit;
   applicationSupervisor: ApplicationSupervisor;
   createError: ApplicationCreateError | null;
   updateError: ApplicationEditError | null;
   applicationTypes: readonly string[];
   searchResults: ApplicationSearchResult | null;
   pendingReviewApplications: readonly ApplicationPendingReviewReadonly[];
   shouldSupervisionValidate: boolean;
   pendingRegistrationApplications: PendingRegistrationApplicationsReadonly;
   pendingRegistrationApplicationCount: number;
   registrationFilesByApplication: File[];
   registrationFileDownload?: Blob;
   requestAnotherFiles: boolean | null;
}

export type ApplicationStateReadonly = Readonly<ApplicationState>;

interface TypeNames {
   GET_APPLICATION_PENDING_RENEWAL_REQUEST: string;
   GET_APPLICATION_PENDING_RENEWAL_SUCCESS: string;
   GET_APPLICATION_PENDING_RENEWAL_FAILURE: string;
   GET_APPLICATION_PENDING_RENEWAL_RESET: string;

   GET_APPLICATION_HISTORY_REQUEST: string;
   GET_APPLICATION_HISTORY_SUCCESS: string;
   GET_APPLICATION_HISTORY_FAILURE: string;
   GET_APPLICATION_HISTORY_RESET: string;

   GET_APPLICATION_FOR_OVERVIEW_REQUEST: string;
   GET_APPLICATION_FOR_OVERVIEW_SUCCESS: string;
   GET_APPLICATION_FOR_OVERVIEW_FAILURE: string;

   GET_APPLICATION_SUPERVISOR_REQUEST: string;
   GET_APPLICATION_SUPERVISOR_SUCCESS: string;
   GET_APPLICATION_SUPERVISOR_FAILURE: string;

   GET_APPLICATION_BY_ID_REQUEST: string;
   GET_APPLICATION_BY_ID_SUCCESS: string;
   GET_APPLICATION_BY_ID_FAILURE: string;

   CREATE_APPLICATION_REQUEST: string;
   CREATE_APPLICATION_SUCCESS: string;
   CREATE_APPLICATION_FAILURE: string;

   UPDATE_APPLICATION_REQUEST: string;
   UPDATE_APPLICATION_SUCCESS: string;
   UPDATE_APPLICATION_FAILURE: string;

   UPDATE_APPLICATION_STATUS_REQUEST: string;
   UPDATE_APPLICATION_STATUS_SUCCESS: string;
   UPDATE_APPLICATION_STATUS_FAILURE: string;

   GET_APPLICATION_TYPES_REQUEST: string;
   GET_APPLICATION_TYPES_SUCCESS: string;
   GET_APPLICATION_TYPES_FAILURE: string;

   SEARCH_APPLICATIONS_REQUEST: string;
   SEARCH_APPLICATIONS_SUCCESS: string;
   SEARCH_APPLICATIONS_FAILURE: string;

   CANCEL_APPLICATION_REQUEST: string;
   CANCEL_APPLICATION_SUCCESS: string;
   CANCEL_APPLICATION_FAILURE: string;

   GET_PENDING_REVIEW_APPLICATIONS_REQUEST: string;
   GET_PENDING_REVIEW_APPLICATIONS_SUCCESS: string;
   GET_PENDING_REVIEW_APPLICATIONS_FAILURE: string;
   GET_PENDING_REVIEW_APPLICATIONS_RESET: string;

   SET_SHOULD_SUPERVISION_VALIDATE: string;

   UPDATE_APPLICATION_RECEIVED_DATE_REQUEST: string;
   UPDATE_APPLICATION_RECEIVED_DATE_SUCCESS: string;
   UPDATE_APPLICATION_RECEIVED_DATE_FAILURE: string;

   GET_PENDING_REGISTRATION_APPLICATIONS_REQUEST: string;
   GET_PENDING_REGISTRATION_APPLICATIONS_SUCCESS: string;
   GET_PENDING_REGISTRATION_APPLICATIONS_FAILURE: string;
   GET_PENDING_REGISTRATION_APPLICATIONS_RESET: string;

   GET_PENDING_REGISTRATION_APPLICATION_COUNT_REQUEST: string;
   GET_PENDING_REGISTRATION_APPLICATION_COUNT_SUCCESS: string;
   GET_PENDING_REGISTRATION_APPLICATION_COUNT_FAILURE: string;

   GET_REGISTRATION_FILES_BY_APPLICATION_REQUEST: string;
   GET_REGISTRATION_FILES_BY_APPLICATION_SUCCESS: string;
   GET_REGISTRATION_FILES_BY_APPLICATION_FAILURE: string;
   GET_REGISTRATION_FILES_BY_APPLICATION_RESET: string;

   GET_REGISTRATION_FILE_DOWNLOAD_REQUEST: string;
   GET_REGISTRATION_FILE_DOWNLOAD_SUCCESS: string;
   GET_REGISTRATION_FILE_DOWNLOAD_FAILURE: string;

   PUT_ACCEPT_FILE_REQUEST: string;
   PUT_ACCEPT_FILE_SUCCESS: string;
   PUT_ACCEPT_FILE_FAILURE: string;

   DELETE_REGISTRATION_FILE_REQUEST: string;
   DELETE_REGISTRATION_FILE_SUCCESS: string;
   DELETE_REGISTRATION_FILE_FAILURE: string;
   CLEAN_REGISTRATION_FILE_RESET: string;

   PUT_REQUEST_ANOTHER_FILES_REQUEST: string;
   PUT_REQUEST_ANOTHER_FILES_SUCCESS: string;
   PUT_REQUEST_ANOTHER_FILES_FAILURE: string;
   PUT_REQUEST_ANOTHER_FILES_RESET: string;

   UPDATE_FEE_REQUEST: string;
   UPDATE_FEE_REQUEST_FAILURE: string;
   UPDATE_FEE_REQUEST_SUCCESS: string;
}

interface SupervisorRequest {
   contactId: number;
   registrationNumber: number;
   licenceYear: number;
   applicationType: string;
}

interface FileType {
   fileTypeID: number;
   fileTypeDescription: string;
   isDeleted: boolean;
}
interface FileMapping {
   applicationID: number;
   isApproved: string;
}
export interface File {
   fileID: number;
   fileTypeID: number;
   contactID: number;
   fileName: string;
   isFileScanFailed: boolean;
   isFileScanCompleted: boolean;
   application: number;
   fileType: FileType;
   fileMappings: FileMapping[];
}

type ApplicationTypeNames = Readonly<TypeNames>;

export interface ApplicationCreators {
   getApplicationPendingRenewalRequest: (id: string) => AnyAction;
   getApplicationPendingRenewalSuccess: (
      data: PendingApplicationResponse | undefined
   ) => AnyAction;
   getApplicationPendingRenewalFailure: (error: ApiError) => AnyAction;
   getApplicationPendingRenewalReset: () => AnyAction;

   getApplicationHistoryRequest: (id: string) => AnyAction;
   getApplicationHistorySuccess: (
      data: readonly ApplicationDto[] | undefined
   ) => AnyAction;
   getApplicationHistoryFailure: (error: ApiError) => AnyAction;
   getApplicationHistoryReset: () => AnyAction;

   getApplicationForOverviewRequest: (id: string) => AnyAction;
   getApplicationForOverviewSuccess: (data: ApplicationEdit) => AnyAction;
   getApplicationForOverviewFailure: (error: ApiError) => AnyAction;

   cancelApplicationRequest: (id: string) => AnyAction;
   cancelApplicationSuccess: (data: ApplicationEdit) => AnyAction;
   cancelApplicationFailure: (error: ApplicationEditError) => AnyAction;

   getApplicationSupervisorRequest: (
      supervisorRequest: SupervisorRequest
   ) => AnyAction;
   getApplicationSupervisorSuccess: (data?: ApplicationSupervisor) => AnyAction;
   getApplicationSupervisorFailure: (error: ApiError) => AnyAction;

   getApplicationByIdRequest: (id: string) => AnyAction;
   getApplicationByIdSuccess: (data: ApplicationDto) => AnyAction;
   getApplicationByIdFailure: (error: ApiError) => AnyAction;

   createApplicationRequest: (createParams: ApplicationCreate) => AnyAction;
   createApplicationSuccess: (data: ApplicationEdit) => AnyAction;
   createApplicationFailure: (error: ApplicationCreateError) => AnyAction;

   updateApplicationRequest: (application: ApplicationEdit) => AnyAction;
   updateApplicationSuccess: (data: ApplicationEdit) => AnyAction;
   updateApplicationFailure: (error: ApplicationEditError) => AnyAction;

   updateApplicationStatusRequest: (
      applicationStatusEdit: ApplicationStatusEdit
   ) => AnyAction;
   updateApplicationStatusSuccess: (
      data: ApplicationPendingReviewReadonly[]
   ) => AnyAction;
   updateApplicationStatusFailure: (error: ApiError) => AnyAction;

   getApplicationTypesRequest: (id: string) => AnyAction;
   getApplicationTypesSuccess: (data: string[]) => AnyAction;
   getApplicationTypesFailure: (error: ApiError) => AnyAction;
   getApplicationTypesReset: () => AnyAction;

   searchApplicationsRequest: (query: ApplicationSearchQuery) => AnyAction;
   searchApplicationsSuccess: (data: ApplicationSearchResult) => AnyAction;
   searchApplicationsFailure: (error: ApiError) => AnyAction;

   getPendingReviewApplicationsRequest: () => AnyAction;
   getPendingReviewApplicationsSuccess: (
      data: readonly ApplicationPendingReviewReadonly[]
   ) => AnyAction;
   getPendingReviewApplicationsFailure: (error: ApiError) => AnyAction;
   getPendingReviewApplicationsReset: () => AnyAction;

   setShouldSupervisionValidate: (
      shouldSupervisionValidate: boolean
   ) => AnyAction;

   updateApplicationReceivedDateRequest: (
      model: ApplicationReceivedDateReadonly
   ) => AnyAction;
   updateApplicationReceivedDateSuccess: () => AnyAction;
   updateApplicationReceivedDateFailure: (error: ApiError) => AnyAction;
   getPendingRegistrationApplicationsRequest: () => AnyAction;
   getPendingRegistrationApplicationsSuccess: (
      data: readonly PendingRegistrationApplicationsReadonly[]
   ) => AnyAction;
   getPendingRegistrationApplicationsFailure: (error: ApiError) => AnyAction;
   getPendingRegistrationApplicationsReset: () => AnyAction;
   getPendingRegistrationApplicationCountRequest: () => AnyAction;
   getPendingRegistrationApplicationCountSuccess: (data: number) => AnyAction;
   getPendingRegistrationApplicationCountFailure: (
      error: ApiError
   ) => AnyAction;

   getRegistrationFilesByApplicationReset: () => AnyAction;
   getRegistrationFilesByApplicationRequest: (id: string) => AnyAction;
   getRegistrationFilesByApplicationSuccess: (data: File[]) => AnyAction;
   getRegistrationFilesByApplicationFailure: (error: ApiError) => AnyAction;

   cleanRegistrationFileReset: () => AnyAction;
   getRegistrationFileDownloadRequest: (id: string) => AnyAction;
   getRegistrationFileDownloadSuccess: (data: Blob) => AnyAction;
   getRegistrationFileDownloadFailure: (error: ApiError) => AnyAction;

   putAcceptFileRequest: (id: string, appId: string) => AnyAction;
   putAcceptFileSuccess: (data: Blob) => AnyAction;
   putAcceptFileFailure: (error: ApiError) => AnyAction;

   deleteRegistrationFileRequest: (id: string, appId: string) => AnyAction;
   deleteRegistrationFileSuccess: (data: boolean, id: string) => AnyAction;
   deleteRegistrationFileFailure: (error: ApiError) => AnyAction;

   putRequestAnotherFilesReset: () => AnyAction;
   putRequestAnotherFilesRequest: (data: ApplicationRequestFiles) => AnyAction;
   putRequestAnotherFilesSuccess: (data: boolean) => AnyAction;
   putRequestAnotherFilesFailure: (error: ApiError) => AnyAction;

   updateFeeRequest: (
      appId: number,
      registrationPaymentType: number
   ) => AnyAction;
   updateFeeRequestSuccess: () => AnyAction;
   updateFeeRequestFailure: (error: ApiError) => AnyAction;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE: ApplicationStateReadonly = {
   pendingApplicationResponse: [],
   applicationHistory: [],
   application: ({} as unknown) as ApplicationDto,
   editModel: ({} as unknown) as ApplicationEdit,
   applicationSupervisor: ({} as unknown) as ApplicationSupervisor,
   createError: ({} as unknown) as ApplicationCreateError,
   updateError: ({} as unknown) as ApplicationEditError,
   applicationTypes: [],
   searchResults: ({} as unknown) as ApplicationSearchResult,
   pendingReviewApplications: [],
   shouldSupervisionValidate: false,
   pendingRegistrationApplications: ({} as unknown) as PendingRegistrationApplications,
   pendingRegistrationApplicationCount: 0,
   registrationFilesByApplication: [],
   registrationFileDownload: undefined,
   requestAnotherFiles: null,
};

/* ------------- Reducers ------------- */
const getApplicationByIdSuccess = (
   state: ApplicationStateReadonly,
   action: AnyAction
): ApplicationStateReadonly => {
   return { ...state, application: action.data };
};

const getApplicationPendingRenewalSuccess = (
   state: ApplicationStateReadonly,
   action: AnyAction
): ApplicationStateReadonly => {
   return { ...state, pendingApplicationResponse: action.data };
};

const getApplicationPendingRenewalReset = (
   state: ApplicationStateReadonly,
   action: AnyAction
): ApplicationStateReadonly => {
   return { ...state, pendingApplicationResponse: [] };
};

const getApplicationHistorySuccess = (
   state: ApplicationStateReadonly,
   action: AnyAction
): ApplicationStateReadonly => {
   return { ...state, applicationHistory: action.data };
};

const getApplicationHistoryReset = (
   state: ApplicationStateReadonly,
   action: AnyAction
): ApplicationStateReadonly => {
   return { ...state, applicationHistory: [] };
};

const getPendingReviewApplicationsSuccess = (
   state: ApplicationStateReadonly,
   action: AnyAction
): ApplicationStateReadonly => {
   return { ...state, pendingReviewApplications: action.data };
};

const getPendingReviewApplicationsReset = (
   state: ApplicationStateReadonly,
   action: AnyAction
): ApplicationStateReadonly => {
   return { ...state, pendingReviewApplications: [] };
};

const getApplicationForOverviewSuccess = (
   state: ApplicationStateReadonly,
   action: AnyAction
): ApplicationStateReadonly => {
   return { ...state, editModel: action.data };
};

const getApplicationSupervisorSuccess = (
   state: ApplicationStateReadonly,
   action: AnyAction
): ApplicationStateReadonly => {
   return {
      ...state,
      editModel: {
         ...state.editModel,
         supervisor: action.data
            ? (action.data as ApplicationSupervisor)
            : null,
      },
   };
};

const upsertApplicationSuccess = (
   state: ApplicationStateReadonly,
   action: AnyAction
): ApplicationStateReadonly => {
   return {
      ...state,
      editModel: action.data as ApplicationEdit,
      updateError: null,
      createError: null,
   };
};

const upsertApplicationStatusSuccess = (
   state: ApplicationStateReadonly,
   action: AnyAction
): ApplicationStateReadonly => {
   return { ...state, pendingReviewApplications: action.data };
};

const createApplicationFailure = (
   state: ApplicationStateReadonly,
   action: AnyAction
): ApplicationStateReadonly => {
   return {
      ...state,
      createError: action.error as ApplicationCreateError,
   };
};

const updateApplicationFailure = (
   state: ApplicationStateReadonly,
   action: AnyAction
): ApplicationStateReadonly => {
   return {
      ...state,
      updateError: action.error as ApplicationEditError,
   };
};

const getApplicationTypesSuccess = (
   state: ApplicationStateReadonly,
   action: AnyAction
): ApplicationStateReadonly => {
   return {
      ...state,
      applicationTypes: action.data as string[],
   };
};

const searchApplicationsSuccess = (
   state: ApplicationStateReadonly,
   action: AnyAction
): ApplicationStateReadonly => {
   return { ...state, searchResults: action.data || [] };
};

const searchApplicationsFailure = (
   state: ApplicationStateReadonly
): ApplicationStateReadonly => {
   return { ...state, searchResults: null };
};

const setShouldSupervisionValidate = (
   state: ApplicationStateReadonly,
   action: AnyAction
): ApplicationStateReadonly => {
   return {
      ...state,
      shouldSupervisionValidate: action.shouldSupervisionValidate,
   };
};

const getPendingRegistrationApplicationsSuccess = (
   state: ApplicationStateReadonly,
   action: AnyAction
): ApplicationStateReadonly => {
   return {
      ...state,
      pendingRegistrationApplications: {
         list: action.data,
         // lastUpdatedDate: new Date(Date.now()),
         lastUpdatedDate: moment().format(DISPLAY_DATE_TIME_FORMAT_12),
      },
   };
};

const getPendingRegistrationApplicationsReset = (
   state: ApplicationStateReadonly,
   action: AnyAction
): ApplicationStateReadonly => {
   return {
      ...state,
      pendingRegistrationApplications: {
         list: [],
         lastUpdatedDate: '',
      },
   };
};

const getPendingRegistrationApplicationCountSuccess = (
   state: ApplicationStateReadonly,
   action: AnyAction
): ApplicationStateReadonly => {
   return {
      ...state,
      pendingRegistrationApplicationCount: action.data,
   };
};

const getPendingRegistrationApplicationCountFailure = (
   state: ApplicationStateReadonly
): ApplicationStateReadonly => {
   return { ...state, pendingRegistrationApplicationCount: 0 };
};

const getRegistrationFilesByApplicationSuccess = (
   state: ApplicationStateReadonly,
   action: AnyAction
): ApplicationStateReadonly => {
   return { ...state, registrationFilesByApplication: action.data };
};

const getRegistrationFilesByApplicationFailure = (
   state: ApplicationStateReadonly,
   action: AnyAction
): ApplicationStateReadonly => {
   return {
      ...state,
      registrationFilesByApplication: [],
      updateError: action.error as ApplicationEditError,
   };
};
const getRegistrationFilesByApplicationReset = (
   state: ApplicationStateReadonly
): ApplicationStateReadonly => {
   return { ...state, registrationFilesByApplication: [] };
};

const getRegistrationFileDownloadSuccess = (
   state: ApplicationStateReadonly,
   action: AnyAction
): ApplicationStateReadonly => {
   return { ...state, registrationFileDownload: action.data };
};

const getRegistrationFileDownloadFailure = (
   state: ApplicationStateReadonly
): ApplicationStateReadonly => {
   return { ...state, registrationFileDownload: undefined };
};

const putAcceptFileFailure = (
   state: ApplicationStateReadonly
): ApplicationStateReadonly => {
   return { ...state, registrationFileDownload: undefined };
};

const putAcceptFileSuccess = (
   state: ApplicationStateReadonly,
   action: AnyAction
): ApplicationStateReadonly => {
   return { ...state, registrationFileDownload: action.data };
};

const deleteRegistrationFileSuccess = (
   state: ApplicationStateReadonly,
   action: AnyAction
): ApplicationStateReadonly => {
   //remove from the state the one which was deleted
   const listWithoutFile = state.registrationFilesByApplication.filter(
      x => +x.fileID !== +action.id
   );
   return {
      ...state,
      registrationFilesByApplication: listWithoutFile,
   };
};
const deleteRegistrationFileFailure = (
   state: ApplicationStateReadonly
): ApplicationStateReadonly => {
   return { ...state };
};
const cleanRegistrationFileReset = (
   state: ApplicationStateReadonly
): ApplicationStateReadonly => {
   return { ...state, registrationFileDownload: undefined };
};

const putRequestAnotherFilesReset = (
   state: ApplicationStateReadonly
): ApplicationStateReadonly => {
   return {
      ...state,
      requestAnotherFiles: null,
   };
};
const putRequestAnotherFilesSuccess = (
   state: ApplicationStateReadonly,
   action: AnyAction
): ApplicationStateReadonly => {
   return {
      ...state,
      requestAnotherFiles: action.data,
   };
};

const putRequestAnotherFilesFailure = (
   state: ApplicationStateReadonly
): ApplicationStateReadonly => {
   return { ...state, requestAnotherFiles: false };
};

const updateFeeRequestSuccess = (
   state: ApplicationStateReadonly,
   action: AnyAction
): ApplicationStateReadonly => {
   return {
      ...state,
   };
};

const updateFeeRequestFailure = (
   state: ApplicationStateReadonly
): ApplicationStateReadonly => {
   return { ...state };
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createScopedActions<
   ApplicationTypeNames,
   ApplicationCreators
>('application', {
   getApplicationPendingRenewalRequest: ['id'],
   getApplicationPendingRenewalSuccess: ['data'],
   getApplicationPendingRenewalFailure: ['error'],
   getApplicationPendingRenewalReset: [],

   getApplicationHistoryRequest: ['id'],
   getApplicationHistorySuccess: ['data'],
   getApplicationHistoryFailure: ['error'],
   getApplicationHistoryReset: [],

   getApplicationForOverviewRequest: ['id'],
   getApplicationForOverviewSuccess: ['data'],
   getApplicationForOverviewFailure: ['error'],

   getApplicationSupervisorRequest: ['supervisorRequest'],
   getApplicationSupervisorSuccess: ['data'],
   getApplicationSupervisorFailure: ['error'],

   getApplicationByIdRequest: ['id'],
   getApplicationByIdSuccess: ['data'],
   getApplicationByIdFailure: ['error'],

   createApplicationRequest: ['createParams'],
   createApplicationSuccess: ['data'],
   createApplicationFailure: ['error'],

   updateApplicationRequest: ['updatedApplication'],
   updateApplicationSuccess: ['data'],
   updateApplicationFailure: ['error'],

   updateApplicationStatusRequest: ['applicationStatusEdit'],
   updateApplicationStatusSuccess: ['data'],
   updateApplicationStatusFailure: ['error'],

   getApplicationTypesRequest: ['id'],
   getApplicationTypesSuccess: ['data'],
   getApplicationTypesFailure: ['error'],

   searchApplicationsRequest: ['query'],
   searchApplicationsSuccess: ['data'],
   searchApplicationsFailure: ['error'],

   cancelApplicationRequest: ['id'],
   cancelApplicationSuccess: ['data'],
   cancelApplicationFailure: ['error'],

   getPendingReviewApplicationsRequest: [],
   getPendingReviewApplicationsSuccess: ['data'],
   getPendingReviewApplicationsFailure: ['error'],
   getPendingReviewApplicationsReset: [],

   setShouldSupervisionValidate: ['shouldSupervisionValidate'],

   updateApplicationReceivedDateRequest: ['model'],
   updateApplicationReceivedDateSuccess: [],
   updateApplicationReceivedDateFailure: ['error'],

   getPendingRegistrationApplicationsRequest: [],
   getPendingRegistrationApplicationsSuccess: ['data'],
   getPendingRegistrationApplicationsFailure: ['error'],
   getPendingRegistrationApplicationsReset: [],

   getPendingRegistrationApplicationCountRequest: [],
   getPendingRegistrationApplicationCountSuccess: ['data'],
   getPendingRegistrationApplicationCountFailure: ['error'],

   getRegistrationFilesByApplicationRequest: ['id'],
   getRegistrationFilesByApplicationSuccess: ['data'],
   getRegistrationFilesByApplicationFailure: ['error'],
   getRegistrationFilesByApplicationReset: [],

   getRegistrationFileDownloadRequest: ['id'],
   getRegistrationFileDownloadSuccess: ['data'],
   getRegistrationFileDownloadFailure: ['error'],

   putAcceptFileRequest: ['id', 'appId'],
   putAcceptFileSuccess: ['data'],
   putAcceptFileFailure: ['error'],

   deleteRegistrationFileRequest: ['id', 'appId'],
   deleteRegistrationFileSuccess: ['data', 'id'],
   deleteRegistrationFileFailure: ['error'],
   cleanRegistrationFileReset: [],

   putRequestAnotherFilesReset: [],
   putRequestAnotherFilesRequest: ['data'],
   putRequestAnotherFilesSuccess: ['data'],
   putRequestAnotherFilesFailure: ['error'],

   updateFeeRequest: ['appId', 'registrationPaymentType'],
   updateFeeRequestSuccess: [],
   updateFeeRequestFailure: ['error'],
});

export const ApplicationTypes = Types;
export const ApplicationActions = Creators;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
   [Types.GET_APPLICATION_PENDING_RENEWAL_SUCCESS]: getApplicationPendingRenewalSuccess,
   [Types.GET_APPLICATION_PENDING_RENEWAL_RESET]: getApplicationPendingRenewalReset,
   [Types.GET_APPLICATION_HISTORY_SUCCESS]: getApplicationHistorySuccess,
   [Types.GET_APPLICATION_HISTORY_RESET]: getApplicationHistoryReset,
   [Types.GET_APPLICATION_FOR_OVERVIEW_SUCCESS]: getApplicationForOverviewSuccess,
   [Types.GET_APPLICATION_SUPERVISOR_SUCCESS]: getApplicationSupervisorSuccess,
   [Types.GET_APPLICATION_BY_ID_SUCCESS]: getApplicationByIdSuccess,
   [Types.CREATE_APPLICATION_SUCCESS]: upsertApplicationSuccess,
   [Types.CREATE_APPLICATION_FAILURE]: createApplicationFailure,
   [Types.UPDATE_APPLICATION_SUCCESS]: upsertApplicationSuccess,
   [Types.UPDATE_APPLICATION_FAILURE]: updateApplicationFailure,
   [Types.UPDATE_APPLICATION_STATUS_SUCCESS]: upsertApplicationStatusSuccess,
   [Types.GET_APPLICATION_TYPES_SUCCESS]: getApplicationTypesSuccess,
   [Types.SEARCH_APPLICATIONS_SUCCESS]: searchApplicationsSuccess,
   [Types.SEARCH_APPLICATIONS_FAILURE]: searchApplicationsFailure,
   [Types.CANCEL_APPLICATION_SUCCESS]: upsertApplicationSuccess,
   [Types.CANCEL_APPLICATION_FAILURE]: updateApplicationFailure,
   [Types.GET_PENDING_REVIEW_APPLICATIONS_SUCCESS]: getPendingReviewApplicationsSuccess,
   [Types.GET_PENDING_REVIEW_APPLICATIONS_RESET]: getPendingReviewApplicationsReset,
   [Types.SET_SHOULD_SUPERVISION_VALIDATE]: setShouldSupervisionValidate,
   [Types.GET_PENDING_REGISTRATION_APPLICATIONS_SUCCESS]: getPendingRegistrationApplicationsSuccess,
   [Types.GET_PENDING_REGISTRATION_APPLICATIONS_RESET]: getPendingRegistrationApplicationsReset,
   [Types.GET_PENDING_REGISTRATION_APPLICATION_COUNT_SUCCESS]: getPendingRegistrationApplicationCountSuccess,
   [Types.GET_PENDING_REGISTRATION_APPLICATION_COUNT_FAILURE]: getPendingRegistrationApplicationCountFailure,

   [Types.GET_REGISTRATION_FILES_BY_APPLICATION_SUCCESS]: getRegistrationFilesByApplicationSuccess,
   [Types.GET_REGISTRATION_FILES_BY_APPLICATION_FAILURE]: getRegistrationFilesByApplicationFailure,
   [Types.GET_REGISTRATION_FILES_BY_APPLICATION_RESET]: getRegistrationFilesByApplicationReset,

   // Download file
   [Types.GET_REGISTRATION_FILE_DOWNLOAD_SUCCESS]: getRegistrationFileDownloadSuccess,
   [Types.GET_REGISTRATION_FILE_DOWNLOAD_FAILURE]: getRegistrationFileDownloadFailure,

   // Accept file
   [Types.PUT_ACCEPT_FILE_SUCCESS]: putAcceptFileSuccess,
   [Types.PUT_ACCEPT_FILE_FAILURE]: putAcceptFileFailure,

   // delete file
   [Types.DELETE_REGISTRATION_FILE_SUCCESS]: deleteRegistrationFileSuccess,
   [Types.DELETE_REGISTRATION_FILE_FAILURE]: deleteRegistrationFileFailure,
   [Types.CLEAN_REGISTRATION_FILE_RESET]: cleanRegistrationFileReset,

   // request more files
   [Types.PUT_REQUEST_ANOTHER_FILES_SUCCESS]: putRequestAnotherFilesSuccess,
   [Types.PUT_REQUEST_ANOTHER_FILES_FAILURE]: putRequestAnotherFilesFailure,
   [Types.PUT_REQUEST_ANOTHER_FILES_RESET]: putRequestAnotherFilesReset,

   // Update fee request
   [Types.UPDATE_FEE_REQUEST_SUCCESS]: updateFeeRequestSuccess,
   [Types.UPDATE_FEE_REQUEST_FAILURE]: updateFeeRequestFailure,
});

/* ------------- Selectors ------------- */
export const selectApplication = (state: StateReadonly): ApplicationDto =>
   state.application.application;

export const selectApplicationPendingRenewal = (
   state: StateReadonly
): readonly PendingApplicationResponse[] =>
   state.application.pendingApplicationResponse;

export const selectApplicationHistory = (
   state: StateReadonly
): readonly ApplicationDto[] => state.application.applicationHistory;

export const selectApplicationForOverview = (
   state: StateReadonly
): ApplicationEdit => state.application.editModel;

export const selectCreateApplicationError = (
   state: StateReadonly
): ApplicationCreateError | null => state.application.createError;

export const selectUpdateApplicationError = (
   state: StateReadonly
): ApplicationEditError | null => state.application.updateError;

export const selectApplicationTypes = (
   state: StateReadonly
): readonly string[] => state.application.applicationTypes;

export const selectApplicationSearchResults = (
   state: StateReadonly
): ApplicationSearchResult | null => state.application.searchResults;

export const selectApplicationForEdit = (
   state: StateReadonly
): ApplicationEdit => state.application.editModel;

export const selectPendingReviewApplications = (
   state: StateReadonly
): readonly ApplicationPendingReviewReadonly[] =>
   state.application.pendingReviewApplications;

export const selectShouldSupervisionValidate = (
   state: StateReadonly
): boolean => state.application.shouldSupervisionValidate;

export const selectPendingRegistrationApplications = (
   state: StateReadonly
): PendingRegistrationApplicationsReadonly =>
   state.application.pendingRegistrationApplications;

export const selectPendingRegistrationApplicationCount = (
   state: StateReadonly
): number => state.application.pendingRegistrationApplicationCount;

export const selectRegistrationFilesByApplication = (
   state: StateReadonly
): File[] => state.application.registrationFilesByApplication;

export const selectRegistrationFileDownload = (
   state: StateReadonly
): Blob | undefined => state.application.registrationFileDownload;

export const selectRequestAnotherFiles = (
   state: StateReadonly
): boolean | null => state.application.requestAnotherFiles;
