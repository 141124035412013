import { ApiResponse } from 'apisauce';
import store from 'State/Redux';
import PendingApplicationResponse from 'Models/Application/Data/PendingApplicationResponse';
import ApplicationDto from 'Models/Application/Dto/ApplicationDto';
import { withAccessToken, createApi } from '.';
import { ApplicationEdit } from 'Models/Application/Data/ApplicationEditModel';
import { ApplicationSupervisor } from 'Models/Application/Data/ApplicationSupervisor';
import { ApplicationCreate } from 'Models/Application/Data/ApplicationCreate';
import { ApplicationSearchQuery } from 'Models/Application/Dto/ApplicationSearchQuery';
import ApplicationSearchResult from 'Models/Application/Data/ApplicationSearchResult';
import { ApplicationPendingReviewReadonly } from 'Models/Application/Data/ApplicationPendingReview';
import { ApplicationStatusEdit } from 'Models/Application/Data/ApplicationStatusEdit';
import { ApplicationReceivedDateReadonly } from 'Models/Application/Data/ApplicationReceivedDateUpdate';
import { ApplicationRequestFiles } from 'Models/Application/Data/ApplicationRequestFilesModel';

export interface SupervisorRequest {
   contactId: number;
   registrationNumber: number;
   licenceYear: number;
   applicationType: string;
}

export interface ApplicationServiceType {
   createApplication(
      createParams: ApplicationCreate
   ): Promise<ApiResponse<ApplicationEdit>>;
   updateApplication(
      application: ApplicationEdit
   ): Promise<ApiResponse<ApplicationEdit>>;
   updateApplicationStatus(
      applicationStatusEdit: ApplicationStatusEdit
   ): Promise<ApiResponse<readonly ApplicationPendingReviewReadonly[]>>;
   cancelApplication(id: string): Promise<ApiResponse<ApplicationEdit>>;
   getApplicationPendingRenewal(
      id: string
   ): Promise<ApiResponse<PendingApplicationResponse>>;
   getApplicationById(id: string): Promise<ApiResponse<ApplicationDto>>;
   getApplicationHistory(
      id: string
   ): Promise<ApiResponse<readonly ApplicationDto[]>>;
   getApplicationForOverview(id: string): Promise<ApiResponse<ApplicationEdit>>;
   getApplicationSupervisor(
      supervisorRequest: SupervisorRequest
   ): Promise<ApiResponse<ApplicationSupervisor>>;
   getApplicationTypes(id: string): Promise<ApiResponse<readonly string[]>>;
   searchApplications(
      query: ApplicationSearchQuery
   ): Promise<ApiResponse<ApplicationSearchResult>>;
   getPendingReviewApplications(): Promise<
      ApiResponse<readonly ApplicationPendingReviewReadonly[]>
   >;
   updateApplicationReceivedDate(
      model: ApplicationReceivedDateReadonly
   ): Promise<ApiResponse<string>>;
   getPendingRegistrationApplications(): Promise<
      ApiResponse<readonly ApplicationPendingReviewReadonly[]>
   >;
   getPendingRegistrationApplicationCount(): Promise<ApiResponse<number>>;
   getRegistrationFilesByApplication(id: string): Promise<ApiResponse<File[]>>;
   getRegistrationFileDownload(id: string): Promise<ApiResponse<Blob>>;
   putAcceptFile(id: string, appId: string): Promise<ApiResponse<Blob>>;
   deleteRegistrationFile(
      id: string,
      appId: string
   ): Promise<ApiResponse<void>>;
   putRequestAnotherFiles(
      model: ApplicationRequestFiles
   ): Promise<ApiResponse<boolean>>;
   updateFeeRequest(
      appId: string,
      registrationPaymentType: number
   ): Promise<ApiResponse<void>>;
}

const create = (baseURL: string | undefined): ApplicationServiceType => {
   const api = createApi({ baseURL });

   const blobApi = createApi({
      baseURL,
      headers: {
         Accept: 'application/json',
         'Content-Type': 'application/json',
         'Cache-Control': 'no-store',
      },
      responseType: 'blob',
   });

   const createApplication = (
      createParams: ApplicationCreate
   ): Promise<ApiResponse<ApplicationEdit>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post(`/Application`, createParams);
   };

   const updateApplication = (
      application: ApplicationEdit
   ): Promise<ApiResponse<ApplicationEdit>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.put(
         `/Application/${application.applicationId}`,
         application
      );
   };

   const updateApplicationStatus = (
      applicationStatusEdit: ApplicationStatusEdit
   ): Promise<ApiResponse<ApplicationPendingReviewReadonly[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.put(`/Application/UpdateStatus`, applicationStatusEdit);
   };

   const getApplicationById = (
      id: string
   ): Promise<ApiResponse<ApplicationDto>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Application/${id}`);
   };

   const getApplicationPendingRenewal = (
      id: string
   ): Promise<ApiResponse<PendingApplicationResponse>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Application/PendingRenewal/${id}`);
   };

   const getApplicationHistory = (
      id: string
   ): Promise<ApiResponse<readonly ApplicationDto[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Application/History/${id}`);
   };

   const getApplicationForOverview = (
      id: string
   ): Promise<ApiResponse<ApplicationEdit>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Application/${id}/View`);
   };

   const cancelApplication = (
      id: string
   ): Promise<ApiResponse<ApplicationEdit>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post(`/Application/${id}/Cancel`);
   };

   const getApplicationSupervisor = (
      supervisorRequest: SupervisorRequest
   ): Promise<ApiResponse<ApplicationSupervisor>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      //When an application is created from Admin portal, it opens application details page after successfully creating it.
      //Then to display the supervision details, it calls GetValidSupervisor(), but the registration number is NaN hence the 400 status code is returned.
      if (isNaN(supervisorRequest.registrationNumber)) {
         supervisorRequest.registrationNumber = 0;
      }
      return authApi.get(`/Application/GetValidSupervisor`, supervisorRequest);
   };

   const getApplicationTypes = (
      id: string
   ): Promise<ApiResponse<readonly string[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Application/GetSupportedTypes?contactId=${id}`);
   };

   const searchApplications = (
      query: ApplicationSearchQuery
   ): Promise<ApiResponse<ApplicationSearchResult>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post('/Application/Search', query);
   };

   const getPendingReviewApplications = (): Promise<ApiResponse<
      readonly ApplicationPendingReviewReadonly[]
   >> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get('/Application/PendingReview');
   };

   const updateApplicationReceivedDate = (
      model: ApplicationReceivedDateReadonly
   ): Promise<ApiResponse<string>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.put(`/Application/UpdateReceivedDate/`, model);
   };

   const getPendingRegistrationApplications = (): Promise<ApiResponse<
      readonly ApplicationPendingReviewReadonly[]
   >> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get('/Application/RegistrationApplications');
   };

   const getPendingRegistrationApplicationCount = (): Promise<ApiResponse<
      number
   >> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Application/PendingRegistrationApplications/Count`);
   };

   const getRegistrationFilesByApplication = (
      id: string
   ): Promise<ApiResponse<File[]>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.get(`/Application/${id}/files`);
   };

   const getRegistrationFileDownload = (
      id: string
   ): Promise<ApiResponse<Blob>> => {
      const authApi = withAccessToken(blobApi, store.getState().oidc);
      return authApi.get(`/Application/file/${id}/download`);
   };

   const putAcceptFile = (
      id: string,
      appId: string
   ): Promise<ApiResponse<Blob>> => {
      const authApi = withAccessToken(blobApi, store.getState().oidc);
      return authApi.put(`/Application/${appId}/file/${id}/accept`);
   };

   const deleteRegistrationFile = (
      id: string,
      appId: string
   ): Promise<ApiResponse<void>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.delete(`/Application/${appId}/file/${id}`);
   };

   const putRequestAnotherFiles = (
      model: ApplicationRequestFiles
   ): Promise<ApiResponse<boolean>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.put(`/Application/file/request`, model);
   };

   const updateFeeRequest = (
      appId: string,
      registrationPaymentType: number
   ): Promise<ApiResponse<void>> => {
      const authApi = withAccessToken(api, store.getState().oidc);
      return authApi.post(
         `/Application/feeRequest/${appId}/${registrationPaymentType}`
      );
   };

   return {
      createApplication,
      updateApplication,
      updateApplicationStatus,
      getApplicationById,
      getApplicationPendingRenewal,
      getApplicationHistory,
      getApplicationForOverview,
      getApplicationSupervisor,
      getApplicationTypes,
      searchApplications,
      cancelApplication,
      getPendingReviewApplications,
      updateApplicationReceivedDate,
      getPendingRegistrationApplications,
      getPendingRegistrationApplicationCount,
      getRegistrationFilesByApplication,
      getRegistrationFileDownload,
      deleteRegistrationFile,
      putRequestAnotherFiles,
      putAcceptFile,
      updateFeeRequest,
   };
};

export default {
   create,
};
